/* eslint brace-style: ["error", "stroustrup"] */
import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import axios from 'axios';
import { Autocomplete, Box, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { debounce } from 'lodash';
import * as loglevel from 'loglevel';
import * as L from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import { useMap } from '../../../contexts/map/map-context';
import MapCurrentLocationButton from '../../reusable/MapCurrentLocationButton';
import SEPContext from '../../../contexts/sep-context/SEPContext';
// eslint-disable-next-line import/no-cycle
import SearchOption from './SearchOption';
import AutocompletePaper from '../../mui/styled/AutocompletePaper';
import searchResultType from './services/constants';
import {
  createSearchPromises,
  detectMatches, getSearchEndpoints,
  generateOptionLabelsAndCoordinates,
  processDataMapping,
  processResult, googleDecode, googleAutocomplete, createMarkerIcon,
} from './services/api';
import env from '../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

/* SEARCH */
const LOCIZE_PANEL_NS_SEARCH = 'search_bar';

export default function Search() {
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const { mapRef } = useMap();
  const { t } = useTranslation(LOCIZE_PANEL_NS_SEARCH, { useSuspense: false });

  const [searchString, setSearchString] = useState('');
  const [isLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sessionToken, setSessionToken] = useState(uuidv4());

  const searchMarkerRef = useRef(null);
  const cancelToken = useRef(null);

  useEffect(() => {
    (async () => {
      const newOptions = results
        .flatMap((result) => {
          try {
            const processData = processDataMapping[result.config.meta.name];
            return processData ? processResult(result, processData) : [];
          }
          catch (e) {
            log.error(e);
            return [];
          }
        })
        .filter((option) => {
          const isCountry = (option?.row?.types || []).includes('country');
          const isGoogle = [
            searchResultType.GOOGLE.NAME,
            searchResultType.GOOGLE_AUTOCOMPLETE.NAME,
          ].includes(option.type);
          return !(isGoogle && isCountry);
        })
        .map((option) => detectMatches(option))
        .map((option) => generateOptionLabelsAndCoordinates(jwt, option, t)
          .then((mappedOption) => mappedOption));

      // All the options are sorted based on groupPriority.
      // All options of a given priority can be additionally
      // sorted with a custom logic.
      // const sortedNewOptions = newOptions.sort(globalSorting);
      const newOptionsPromise = await Promise.all(newOptions);

      // We want to limit the amount of Google results to max of 3
      // The requirement is that we take only one result from Google
      // And the rest from Google Autocomplete

      // Separate Google and Google Autocomplete results
      const googleResults = [];
      const googleAutocompleteResults = [];
      newOptionsPromise.forEach((option) => {
        if (option.type === searchResultType.GOOGLE.NAME) {
          googleResults.push(option);
        }
        else if (option.type === searchResultType.GOOGLE_AUTOCOMPLETE.NAME) {
          googleAutocompleteResults.push(option);
        }
      });

      // Limit each result set to a maximum of 3 elements
      const limitedGoogleResults = googleResults.slice(0, 3);
      const limitedGoogleAutocompleteResults = googleAutocompleteResults.slice(0, 3);

      // Merge the results with priority for Google Autocomplete
      // Limit the total amount of results to 3
      const mergedResults = [
        ...limitedGoogleAutocompleteResults.slice(0, 2),
        ...limitedGoogleResults.slice(0, 1),
        ...limitedGoogleAutocompleteResults.slice(2),
      ].splice(0, 3);

      // Combine the Google results with the rest of the results
      const combinedResults = newOptionsPromise
        .filter((option) => ![
          searchResultType.GOOGLE.NAME,
          searchResultType.GOOGLE_AUTOCOMPLETE.NAME].includes(option.type))
        .concat(mergedResults);

      // The results may arrive in an arbitrary order since all requests are started in parallel.
      // To maintain consistent ordering of dropdown items, we need to implement sorting.
      // Sorting order: Addresses, Parcels, ZIP Codes (PLZ), Google
      const sortedNewOptions = combinedResults
        .sort((a, b) => a.groupPriority - b.groupPriority)
        // remove duplicates and options with no label
        .filter((option, index, self) => {
          const isDuplicate = self.findIndex((o) => o.label === option.label) !== index;
          const hasLabel = option.label !== null;
          return !isDuplicate && hasLabel;
        });

      setOptions(sortedNewOptions);
    })();
  }, [jwt, results, t]);

  const fetchOptions = useCallback(async (newInputValue) => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled by the user.');
    }
    setOptions([]);
    cancelToken.current = axios.CancelToken.source();

    try {
      const { token } = cancelToken.current;
      const [googleDecodeResponse, googlePlacesACResponse] = await Promise.all([
        googleDecode(jwt, newInputValue, token, sessionToken),
        googleAutocomplete(jwt, newInputValue, token, sessionToken),
      ]);

      // extract place_ids from googleDecodeResponse
      const decodedPlaceIds = [];
      const decodedResults = googleDecodeResponse.data.results.reduce((acc, result) => {
        const placeId = result.place_id;
        if (!decodedPlaceIds.includes(placeId)) {
          decodedPlaceIds.push(placeId);
          acc.data.results.push({ ...result });
        }
        return acc;
      }, {
        query: newInputValue,
        data: {
          results: [],
        },
        config: {
          meta: {
            name: searchResultType.GOOGLE.NAME,
            group: searchResultType.GOOGLE.GROUP,
            groupPriority: searchResultType.GOOGLE.GROUP_PRIORITY,
          },
        },
      });
      // filter out predictions that are already in googleDecodeResponse
      const filteredPredictions = googlePlacesACResponse.data.predictions
        .filter((prediction) => !decodedPlaceIds.includes(prediction.place_id))
        .reduce((acc, prediction) => {
          acc.data.predictions.push({ ...prediction });
          return acc;
        }, {
          query: newInputValue,
          data: {
            predictions: [],
          },
          config: {
            meta: {
              name: searchResultType.GOOGLE_AUTOCOMPLETE.NAME,
              group: searchResultType.GOOGLE.GROUP,
              groupPriority: searchResultType.GOOGLE_AUTOCOMPLETE.GROUP_PRIORITY,
            },
          },
        });

      // this is horrible, but we do that to not refactor the entire search component
      // the issue is the data structure which is not prepared as early as possible
      // for later use in the component
      const googleResults = decodedResults.data.results.length > 0
        ? [{
          ...decodedResults,
          data: {
            ...decodedResults.data,
            results: decodedResults.data.results.slice(0, 1),
          },
        }, {
          ...filteredPredictions,
          data: {
            ...filteredPredictions.data,
            predictions: filteredPredictions.data.predictions.slice(0, 2),
          },
        }]
        : {
          ...filteredPredictions,
          data: {
            ...filteredPredictions.data,
            predictions: filteredPredictions.data.predictions.slice(0, 3),
          },
        };

      // Fetch additional results from other endpoints
      const searchEndpoints = getSearchEndpoints(cancelToken.current.token, newInputValue);
      const searchPromises = createSearchPromises(jwt, searchEndpoints)
        .map((promise) => promise.catch((e) => {
          log.info('Could not resolve promise (search)', e.message);
          return null;
        }));
      const searchResults = (await Promise.all(searchPromises)).filter((r) => r !== null);

      // combine all results and set them
      setResults([...googleResults, ...searchResults]);
    }
    catch (error) {
      if (axios.isCancel(error)) {
        log.info('Request canceled:', error.message);
      }
      else {
        log.error('Error fetching options:', error);
      }
    }
  }, [jwt, sessionToken]);

  const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 400), [fetchOptions]);

  const renderOptionsGroup = (params) => (
    <Box>
      {params.children.map((child, childIndex) => (
        <Box key={JSON.stringify({ params: params.key, childIndex })}>{child}</Box>
      ))}
    </Box>
  );

  const onCrosshairPositionReceived = (coords) => {
    if (!mapRef.current) return;
    try {
      mapRef.current.setView([coords.latitude, coords.longitude], 15);
      const icon = createMarkerIcon();
      const searchMarker = L.marker([coords.latitude, coords.longitude], { icon });
      if (searchMarkerRef.current) {
        mapRef.current.removeLayer(searchMarkerRef.current);
      }
      searchMarkerRef.current = searchMarker.addTo(mapRef.current);
    }
    catch (e) {
      log.warn("onCrosshairPositionReceived - can't set the map view", {
        coords, options, e,
      });
    }
  };

  const renderOption = (props, option) => (
    <SearchOption
      setSearchString={setSearchString}
      option={option}
      setIsOpen={setIsOpen}
      searchMarkerRef={searchMarkerRef}
    />
  );

  const handleInputChange = useCallback((event, newInputValue, reason) => {
    if (reason === 'input') {
      if (!sessionToken) {
        setSessionToken(uuidv4()); // generate new session token when input starts
      }

      // when the user types in the input field
      setSearchString(newInputValue);

      if (newInputValue.length >= 1) {
        debouncedFetchOptions(newInputValue);
      }
      else {
        setOptions([]);
      }
    }
    else if (reason === 'clear') {
      // when the input is cleared (e.g., by clicking the clear button)
      setOptions([]);
      setSearchString('');
      setSessionToken(null);
    }
    else if (reason === 'reset') {
      // when the input value is reset (e.g., when the user selects an option)
      setSearchString(newInputValue);
      setOptions([]); // clear the options as the selection is made
      setSessionToken(null);
    }
  }, [debouncedFetchOptions, sessionToken]);

  const filterOptions = (myOptions) => myOptions;

  return (
    <Box
      className="Search"
      sx={{
        width: '100%',
        '& div.MuiAutocomplete-root': {
          background: 'transparent !important',
        },
        '& .MuiInputBase-root': {
          background: 'white !important',
        },
      }}
    >
      <Autocomplete
        sx={{
          maxWidth: '800px',
          minWidth: '320px',
          width: '100%',
        }}
        value={searchString}
        blurOnSelect
        disableCloseOnSelect={false}
        PaperComponent={AutocompletePaper}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        open={isOpen}
        noOptionsText={t(`${LOCIZE_PANEL_NS_SEARCH}:search-no-options`)}
        loading={isLoading}
        fullWidth
        disablePortal
        className="sep-search"
        options={options}
        groupBy={(option) => option.group}
        renderGroup={(params) => (
          <Box sx={{ width: '100%' }} key={`${params.key}`}>
            <Divider>{t(`${LOCIZE_PANEL_NS_SEARCH}:search-group-${params.group}`)}</Divider>
            {renderOptionsGroup(params)}
          </Box>
        )}
        renderOption={renderOption}
        filterOptions={filterOptions}
        filterSelectedOptions
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            placeholder={t(`${LOCIZE_PANEL_NS_SEARCH}:search-placeholder`)}
            type="text"
            {...params} // eslint-disable-line react/jsx-props-no-spreading
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="end">
                  <MapCurrentLocationButton
                    sx={{ padding: 0 }}
                    onNewLocation={(res) => {
                      onCrosshairPositionReceived(res.coords);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
